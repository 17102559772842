@use 'global';
@use 'colors';
@use 'mixins';
@use 'variables';

body {
  overflow: auto;
  min-height: 100vh;
  background: colors.$grayscale-100;
  @include mixins.scrollbar('transparent', 'm');

  &.auth {
    background: colors.$grayscale-0;

    &.documents,
    &.success,
    &.join-success {
      background: colors.$grayscale-100;
    }
  }

  &:not(.auth) {
    #hj-survey-toggle-1 {
      display: none;

      & + div:not([aria-hidden='true']) {
        position: fixed;
        inset: unset;
        bottom: 90px;
        left: calc(variables.$nav-bar-width + 8px);
        transition: opacity 250ms ease-out;
      }
    }

    onyx-toaster {
      transform: translateX(variables.$nav-bar-width);
    }
  }
}

.o-printable {
  display: none;
  visibility: hidden;
}

@media print {
  @page {
    size: 595px 842px;
    margin: 0;
  }

  body {
    position: relative;
    visibility: hidden;
    background: colors.$grayscale-0;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .o-printable {
    width: 100%;
    position: fixed;
    display: block;
    visibility: visible;
    inset: 0;
  }
}
