@use 'colors';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

body {
  font-family: 'Inter', sans-serif;
  color: colors.$grayscale-900;
}

/* Headline */

h1,
.f-h1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 115%;
  letter-spacing: -0.03em;
}

h2,
.f-h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 115%;
  letter-spacing: -0.03em;
}

h3,
.f-h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.03em;
}

h4,
.f-h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: -0.03em;
}

h5,
.f-h5 {
  font-size: 16px;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.03em;
}

h6,
.f-h6 {
  font-size: 14px;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: -0.025em;
}

.f-h7 {
  font-size: 14px;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.025em;
}

/* Paragraph */

.f-paragraph-semibold-1 {
  font-size: 18px;
  font-weight: 600;
  line-height: 135%;
  letter-spacing: -0.03em;
}

.f-paragraph-medium-1 {
  font-size: 18px;
  font-weight: 500;
  line-height: 135%;
  letter-spacing: -0.03em;
}

.f-paragraph-regular-1 {
  font-size: 18px;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: -0.03em;
}

.f-paragraph-semibold-2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 145%;
  letter-spacing: -0.025em;
}

.f-paragraph-medium-2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 145%;
  letter-spacing: -0.025em;
}

.f-paragraph-regular-2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  letter-spacing: -0.025em;
}

.f-paragraph-semibold-3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 155%;
  letter-spacing: -0.025em;
}

.f-paragraph-medium-3 {
  font-size: 14px;
  font-weight: 500;
  line-height: 155%;
  letter-spacing: -0.025em;
}

.f-paragraph-regular-3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 155%;
  letter-spacing: -0.025em;
}

.f-paragraph-semibold-4 {
  font-size: 13px;
  font-weight: 600;
  line-height: 155%;
  letter-spacing: -0.025em;
}

.f-paragraph-medium-4 {
  font-size: 13px;
  font-weight: 500;
  line-height: 155%;
  letter-spacing: -0.025em;
}

.f-paragraph-regular-4 {
  font-size: 13px;
  font-weight: 400;
  line-height: 155%;
  letter-spacing: -0.025em;
}

/* Text */

.f-medium-1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 145%;
  letter-spacing: -0.025em;
}

.f-regular-1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 145%;
  letter-spacing: -0.025em;
}

.f-semibold-2 {
  font-size: 13px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.025em;
}

.f-medium-2 {
  font-size: 13px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.025em;
}

.f-regular-2,
body {
  font-size: 13px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.025em;
}

.f-semibold-3 {
  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.025em;
}

.f-medium-3 {
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.025em;
}

.f-regular-3 {
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.025em;
}

.f-semibold-4 {
  font-size: 11px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.02em;
}

.f-medium-4 {
  font-size: 11px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.02em;
}

.f-regular-4 {
  font-size: 11px;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.02em;
}

.f-medium-5 {
  font-size: 10px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.02em;
}

.f-regular-5 {
  font-size: 10px;
  font-weight: 400;
  line-height: 100%;
}

/* Link */

.f-link-1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 145%;
  letter-spacing: -0.025em;
}

.f-link-2 {
  font-size: 13px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.025em;
}

.f-link-3 {
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.025em;
}

.f-link-4 {
  font-size: 11px;
  font-weight: 400;
  line-height: 105%;
  letter-spacing: -0.025em;
}

.f-link {
  &,
  &-1,
  &-2,
  &-3,
  &-4 {
    text-underline-position: from-font;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

/* Label */

.f-label-1 {
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.025em;
}

.f-label-2 {
  font-size: 11px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.025em;
}

.f-label-3 {
  font-size: 9px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.02em;
}

/* Others */

.f-italic {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  line-height: 120%;
  letter-spacing: -0.025em;
}

.f-highlight {
  color: colors.$blue-700;
}

.f-gray {
  color: colors.$grayscale-500;
}

.f-red {
  color: colors.$red-500;
}
