@use 'sass:math';

@use 'colors';
@use 'variables';

/* Animations */
@mixin pulse($color: colors.$blue-700) {
  &::before {
    --color: var(--pulse-color, #{$color});
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    pointer-events: none;
    animation: pulse 1200ms ease-out infinite;

    @keyframes pulse {
      from {
        background: var(--color);
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
      to {
        background: var(--color);
        opacity: 0;
        transform: translate(-50%, -50%) scale(calc(80 / 15));
      }
    }
  }
}

/* Focus */

@mixin focus-outline($border-radius, $offset: 2px, $color: colors.$blue-700) {
  position: relative;
  outline: none;

  &::after {
    $border-width: 2px;
    $inset: -($border-width + $offset);

    content: '';
    position: absolute;
    inset: $inset;
    border: $border-width solid $color;
    pointer-events: none;

    @if (math.unit($border-radius) == '%') {
      border-radius: $border-radius;
    } @else {
      border-radius: $border-radius + abs($inset);
    }
  }
}

/* Shadows */

@mixin shadow($level) {
  @if $level == 100 {
    box-shadow: 0 1px 2px 0 rgba(colors.$grayscale-900, 0.25);
  } @else if $level == 200 {
    box-shadow: 0 2px 4px 0 rgba(colors.$grayscale-900, 0.25);
  } @else if $level == 300 {
    box-shadow: 0 2px 4px 0 rgba(colors.$grayscale-900, 0.4);
  } @else if $level == 400 {
    box-shadow: 0 3px 10px 0 rgba(colors.$grayscale-900, 0.3);
  } @else if $level == 500 {
    box-shadow: 0 8px 20px 0 rgba(colors.$grayscale-900, 0.25);
  }
}

/* Scrollbars */

@mixin scrollbar($type, $size) {
  &::-webkit-scrollbar-thumb {
    background-color: colors.$grayscale-300;
    background-clip: content-box;

    &:hover {
      background-color: colors.$grayscale-400;
    }

    &:active {
      background-color: colors.$grayscale-500;
    }
  }

  @if $size == 's' {
    &::-webkit-scrollbar {
      width: variables.$scrollbar-s-size;
      height: variables.$scrollbar-s-size;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      border-radius: 6px;
    }
  } @else if $size == 'm' {
    &::-webkit-scrollbar {
      width: variables.$scrollbar-m-size;
      height: variables.$scrollbar-m-size;
    }

    &::-webkit-scrollbar-thumb {
      border: 6px solid transparent;
      border-radius: 8px;
    }
  }

  @if $type == 'background' {
    &::-webkit-scrollbar-track {
      background-color: colors.$grayscale-0;
    }
  } @else if $type == 'transparent' {
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
}

@mixin horizontal-scrollbar {
  &::-webkit-scrollbar {
    width: 100%;
    height: variables.$horizontal-scrollbar-size;
  }

  &::-webkit-scrollbar-thumb {
    background-color: colors.$grayscale-300;
    background-clip: content-box;
    border: 8px solid transparent;
    border-radius: 10px;

    &:hover {
      background-color: colors.$grayscale-400;
    }

    &:active {
      background-color: colors.$grayscale-500;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: colors.$grayscale-0;
  }
}
