@use 'colors';

$z-index-interface: 1000;
$z-index-map: 2000;
$z-index-overlay: 3000;
$z-index-modal: $z-index-overlay;
$z-index-dropdown: 4000;
$z-index-tooltip: $z-index-dropdown;
$z-index-toaster: 5000;

$radius-0-5: 2px;
$radius-1: $radius-0-5 * 2;
$radius-1-5: $radius-1 * 1.5;
$radius-2: $radius-1 * 2;
$radius-2-5: $radius-1 * 2.5;
$radius-3: $radius-1 * 3;

$shadow-active-input: 0 0 0 3px rgba(colors.$blue-700, 0.25);
$shadow-map: 0 2px 4px 0 rgba(colors.$grayscale-900, 0.4);

$scrollbar-s-size: 12px;
$scrollbar-m-size: 16px;
$horizontal-scrollbar-size: 20px;

$nav-bar-width: 64px;
$input-label-height: 20px;
$icon-box-size: 24px;

$cubic-bezier-timing: cubic-bezier(0.45, 1.45, 0.8, 1);

$form-breakpoint: 1500px;

$colors: (
  'white': (
    colors.$grayscale-0,
    colors.$grayscale-900,
  ),
  'white-alt': (
    colors.$grayscale-50,
    colors.$grayscale-900,
  ),
  'red': (
    colors.$red-500,
    colors.$grayscale-0,
  ),
  'orange': (
    colors.$orange-500,
    colors.$grayscale-0,
  ),
  'yellow': (
    colors.$yellow-300,
    colors.$grayscale-900,
  ),
  'yellow-alt': (
    colors.$yellow-500,
    colors.$grayscale-900,
  ),
  'green': (
    colors.$green-600,
    colors.$grayscale-0,
  ),
  'green-alt': (
    colors.$green-500,
    colors.$grayscale-0,
  ),
  'sea-green': (
    colors.$sea-green-500,
    colors.$grayscale-0,
  ),
  'blue': (
    colors.$blue-700,
    colors.$grayscale-0,
  ),
  'blue-alt': (
    colors.$blue-500,
    colors.$grayscale-0,
  ),
  'blue-alt-dark': (
    colors.$blue-600,
    colors.$grayscale-0,
  ),
  'violet': (
    colors.$violet-500,
    colors.$grayscale-0,
  ),
  'pink': (
    colors.$pink-500,
    colors.$grayscale-0,
  ),
  'gold': (
    colors.$yellow-600,
    colors.$grayscale-0,
  ),
  'gray': (
    colors.$grayscale-500,
    colors.$grayscale-0,
  ),
  'gray-alt': (
    colors.$grayscale-400,
    colors.$grayscale-0,
  ),
  'black': (
    colors.$grayscale-900,
    colors.$grayscale-0,
  ),

  'light-red': (
    colors.$red-200,
    colors.$red-500,
  ),
  'light-orange': (
    colors.$orange-200,
    colors.$orange-500,
  ),
  'light-yellow': (
    colors.$yellow-200,
    colors.$yellow-600,
  ),
  'light-green': (
    colors.$green-200,
    colors.$green-600,
  ),
  'light-green-alt': (
    colors.$green-200,
    colors.$green-500,
  ),
  'light-sea-green': (
    colors.$sea-green-600,
    colors.$sea-green-200,
  ),
  'light-blue': (
    colors.$blue-200,
    colors.$blue-600,
  ),
  'light-blue-alt': (
    colors.$blue-200,
    colors.$blue-500,
  ),
  'light-violet': (
    colors.$violet-200,
    colors.$violet-500,
  ),
  'light-pink': (
    colors.$pink-200,
    colors.$pink-500,
  ),
  'light-gold': (
    #f0dcb4,
    colors.$yellow-600,
  ),
  'light-gray': (
    colors.$grayscale-200,
    colors.$grayscale-900,
  ),
  'light-gray-alt': (
    colors.$grayscale-150,
    colors.$grayscale-900,
  ),
  'light-black': (
    colors.$grayscale-300,
    colors.$grayscale-900,
  ),
);

$icon-box-colors: (
  'violet': colors.$violet-500,
  'orange': colors.$orange-500,
  'green': colors.$green-600,
  'gray': colors.$grayscale-400,
  'dark-gray': colors.$grayscale-500,
  'blue': colors.$blue-700,
  'black': colors.$grayscale-900,
  'red': colors.$red-500,
);

$widget-colors: (
  'gray': (
    base: colors.$grayscale-150,
    hover: colors.$grayscale-200,
    selected: colors.$grayscale-500,
  ),
  'green': (
    base: rgba(colors.$green-600, 0.15),
    hover: rgba(colors.$green-600, 0.22),
    selected: colors.$green-600,
  ),
  'red': (
    base: rgba(colors.$red-500, 0.15),
    hover: rgba(colors.$red-500, 0.22),
    selected: colors.$red-600,
  ),
  'yellow': (
    base: rgba(colors.$yellow-300, 0.3),
    hover: rgba(colors.$yellow-300, 0.44),
    selected: colors.$yellow-400,
    small: colors.$grayscale-900,
  ),
  'orange': (
    base: rgba(colors.$orange-500, 0.15),
    hover: rgba(colors.$orange-500, 0.22),
    selected: colors.$orange-500,
  ),
  'blue': (
    base: rgba(colors.$blue-700, 0.15),
    hover: rgba(colors.$blue-700, 0.22),
    selected: colors.$blue-600,
  ),
  'violet': (
    base: rgba(colors.$violet-500, 0.15),
    hover: rgba(colors.$violet-500, 0.22),
    selected: colors.$violet-600,
  ),
);
