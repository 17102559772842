@use 'colors';
@use 'variables';

@import 'reset';
@import 'typography';

.cdk-overlay-container {
  z-index: variables.$z-index-overlay;
  position: fixed;
  inset: 0;
  pointer-events: none;

  .cdk-global-overlay-wrapper {
    position: absolute;
    display: flex;
    pointer-events: none;

    &:has(onyx-toaster) {
      z-index: variables.$z-index-toaster;
    }
  }

  .cdk-overlay-connected-position-bounding-box {
    min-width: 1px;
    min-height: 1px;
    z-index: variables.$z-index-tooltip;
    position: absolute;
    display: flex;
    flex-direction: column;
  }

  .cdk-overlay-pane {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    display: flex;
    pointer-events: auto;
  }
}

.cdk-global-scrollblock,
body:has(onyx-map.maximized) {
  overflow-y: hidden;
}
