/* Grayscale */
$grayscale-0: #ffffff;
$grayscale-50: #f8fafc;
$grayscale-100: #f1f5f9;
$grayscale-150: #e9eef4;
$grayscale-200: #e2e8f0;
$grayscale-300: #cbd5e1;
$grayscale-400: #94a3b8;
$grayscale-450: #8390a2;
$grayscale-500: #64748b;
$grayscale-600: #475569;
$grayscale-700: #334155;
$grayscale-800: #1e293b;
$grayscale-900: #0f172a;

/* Blue */
$blue-50: #edfaff;
$blue-100: #d6f2ff;
$blue-200: #b5e9ff;
$blue-300: #83deff;
$blue-400: #48c9ff;
$blue-500: #1eaaff;
$blue-600: #068cff;
$blue-700: #0078ff;
$blue-800: #085bc5;
$blue-900: #0d509b;

/* Green */
$green-50: #f0fdf4;
$green-100: #dcfce7;
$green-200: #bbf7d0;
$green-300: #86efac;
$green-400: #4ade80;
$green-500: #22c55e;
$green-600: #16a34a;
$green-700: #15803d;
$green-800: #166534;
$green-900: #14532d;

/* Red */
$red-50: #fdeded;
$red-100: #fcdada;
$red-200: #f9b5b5;
$red-300: #f58f8f;
$red-400: #f26a6a;
$red-500: #ef4444;
$red-600: #e71414;
$red-700: #cf1212;
$red-900: #4c0707;

/* Yellow */
$yellow-50: #fefce8;
$yellow-100: #fef9c3;
$yellow-200: #fef08a;
$yellow-300: #fde047;
$yellow-400: #facc15;
$yellow-500: #eab308;
$yellow-600: #ca8a04;
$yellow-900: #422006;

/* Violet */
$violet-50: #f5f3ff;
$violet-100: #ede9fe;
$violet-200: #ddd6fe;
$violet-300: #c4b5fd;
$violet-400: #a78bfa;
$violet-500: #8b5cf6;
$violet-600: #7c3aed;
$violet-900: #4c1d95;

/* Orange */
$orange-50: #fff7ed;
$orange-100: #ffedd5;
$orange-200: #fed7aa;
$orange-300: #fdba74;
$orange-400: #fb923c;
$orange-500: #f97316;
$orange-600: #ea580c;
$orange-700: #d24f0a;
$orange-900: #431407;

/* Pink */
$pink-50: #fdf2f8;
$pink-100: #fce7f3;
$pink-200: #fbcfe8;
$pink-300: #f9a8d4;
$pink-400: #f472b6;
$pink-500: #ec4899;
$pink-600: #db2777;
$pink-900: #500724;

/* Sea green */
$sea-green-50: #eaf6f9;
$sea-green-100: #d4ebf2;
$sea-green-200: #a9d8e5;
$sea-green-300: #7ec4d7;
$sea-green-400: #53b1ca;
$sea-green-500: #289dbd;
$sea-green-600: #1a8baa;
$sea-green-900: #0d5568;
